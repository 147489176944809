// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";
@import "home";
@import "homeMobile";
@import "incidence";
@import "adminlte";

// summernote
@import "node_modules/codemirror/theme/monokai";
@import "node_modules/summernote/dist/summernote";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";

//Admin LTE
@import "admin";
@import "node_modules/admin-lte/plugins/datatables/dataTables.bootstrap";
@import "node_modules/admin-lte/plugins/datepicker/datepicker3";
@import "node_modules/admin-lte/dist/css/skins/_all-skins";

@import "node_modules/bootstrap-select/sass/variables";
@import "node_modules/bootstrap-select/sass/bootstrap-select";