.bg-brown {
  background-color: #19d0c7;
  color: white;
}

.bg-cornfloweblue {
  background-color: #d0b919;
  color: white;
}
.bg-red {
  background-color: #d04183;
  color: white;
}

.bg-aqua {
  background-color: #30bcff;
  color: white;
}
.bg-green {
   background-color: #75c339;
   color: white;
 }

.bg-yellow {
  background-color: cornflowerblue;
  color: white;
}
.small-box p {
  font-size: 20px;
  line-height: 55px;
}
.content-header > h1 {
  font-size: 25px;
}
.panel-body > h2 {
  margin-top: 8px;
  margin-bottom: 25px;
}
body {
  font-size: 16px;
}
.sidebar-menu .treeview-menu >li > a {
  font-size: 16px;
}
//.sidebar-menu > li > a > .fa,
//.sidebar-menu > li > a > .glyphicon,
//.sidebar-menu > li > a > .ion {
//  font-size: 30px;
//}
//.sidebar-menu .treeview-menu > li > a > .fa,
//.sidebar-menu .treeview-menu > li > a > .glyphicon,
//.sidebar-menu .treeview-menu > li > a > .ion {
//  font-size: 30px;
//}
.sidebar-menu .treeview-menu > li > a {
  margin: 10px 5px 10px 15px;
}
.skin-blue .sidebar a {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: #b51d5c;
  border-color: #b51d5c;
}
h4 {
  font-size: 16px;
}
.sidebar-menu li.treeview-menu a span {
  margin-left: 18px;
}
.content-wrapper li.active a {
  background: white !important;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #f3f3f3 !important;
}
.btn-success {
  background-color: #60b52c !important;
  border-color: #55a027 !important;
}
.btn-danger{
  background-color: #da341f !important;
  border-color: #ce3623 !important;
}
.btn-microsoft {
  background-color: #1882b5 !important;
}
.btn-dropbox {
  background-color: #269df1 !important;
}
.estatisticas-label {
  margin-right: 30px;
}
.form-error {
  border-color: #ce3623 !important;
}
@media(max-width: 620px) {
  .bg-secondary {
    min-height: 65px !important;
  }
  #footer{
    height: 70px !important;
    text-align: center;
  }
  #footer .pull-right {
    float: inherit !important;
  }
}
