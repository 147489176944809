
@media (min-width: 1200px) {
  #mapa {
    height: 700px;
    margin: 0px;
    padding: 0px
  }
  #imaxe-modal{
    width: 100%;

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #mapa {
    height: 700px;
    margin: 0px;
    padding: 0px
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #mapa {
    height: 700px;
    margin: 0px;
    padding: 0px
  }
}

@media (max-width: 767px) {
  #mapa {
    height: 500px;
    margin: 0px;
    padding: 0px
  }
}

@media (max-width: 480px) {
  #mapa {
    height: 400px;
    margin: 0px;
    padding: 0px
  }
}

#formIncidencia {
  button.btn.dropdown-toggle.btn-default {
    height: 100%;
  }

  div.inner.open {
    max-height: 300px !important;
  }
}

