body {
  background-color: white !important;

  .bg-primary {
    background-color: $bg-primary;
  }
  .bg-secondary {
    background-color: $bg-secondary;
  }
  .bg-blue-light {
    background-color: $bg-blue-light;
  }
  .bg-grey {
    background-color: $grey;
  }

  .logo-peque {
    height: auto;
    width: 30px;
  }

  #app {
    margin-top: 0px;
  }

  /*Navbar*/
  nav {

    .navbar-height {
      min-height: 90px;
    }

    &.navbar.navbar-default.line-header.bg-primary {
      border-color: inherit;
    }

    h1 {
      &.title-pg-index {
        font-size: 30px;
        text-align: center;
        margin-top: 28px;
      }
    }

    .multi-idioma {
      z-index: 99;
      position: absolute;
      right: 0.8%;
      top: 8px;
      color: #fff;

      li {
        display: inline-block;
        padding: 2px 4px;
      }
      a {
        font-size: 1.25rem;
        color: #fff;
        text-decoration: none !important;

        &:hover {
          color: #fff;
        }
      }
    }

    /*++++ CUSTOM MULTI IDIOMA ++++*/
    .multi-idioma-custom {
      /*z-index: 99;
      position: absolute;
      right: 0.8%;
      top: 8px;*/
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-right: 0.8%;
      color: #fff;

      li {
        display: inline-block;
        padding: 2px 4px;
      }
      a {
        font-size: 1.25rem;
        color: #fff;
        text-decoration: none !important;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .column-1 {
    padding-right: 60px;
    @media (max-width: 992px) {
      padding: 0 15px;
    }
  }

  .column-2 {
    padding-left: 60px;
    border-left: 1px solid $border-color;
    min-height: 950px;
    @media (max-width: 992px) {
      padding: 0 15px;
    }
    @media (max-width: 768px) {
      border-left: none;
      min-height: unset;
    }
  }

  /*Botones*/
  button {
    &.btn {

    }
    &.btn-gardar:hover {
      color: white;
      font-weight: 900;
      letter-spacing: 1px;
    }
    &.btn-green {
      background: $bg-green;
      border: none;
      padding: 10px 25px;
      margin-top: 15px;
      color: $white;
    }
    &.btn-save {
      width: 65%;
    }

  }

  .btn {

    &.btn-green {
      background: $bg-green;
      border: none;
      padding: 10px 25px;
      margin-top: 15px;
      color: $white;
    }
  }

  .btn-change {
    width: 20%;
    background-color: $bg-change;
    display: inline-block;
    padding: 6px 0;
    text-align: center;
    color: $white;
    &:hover {

    }
  }

  label {
    &.text-block-blue-light {
      width: 80%;
      padding: 6px 12px;
      margin-bottom: 0;
      color: $white;
    }
  }

  /*Imagenes*/
  img {
    &.ico.logo-public {
      width: 95%;
      max-width: 300px;
    }
  }

  #mapid {
    height: 350px;
  }

  #bloque-usuario {
    h2 {
      margin: 0;
      padding: 15px 0;
      font-size: 22px;
      font-weight: 200;
    }
    .fa {
      display: table-cell !important;
    }
    //Formularios
    input {
      &.form-control.p-1 {
        padding: 25px 15px;
      }
      &.form-control {
        border: none;
      }

    }
  }

  /*Footer*/
  footer {
    background-color: $bg-primary;
    padding: 10px 0;
    text-align: center;
    color: $white;
  }

}

#formIncidencia {
  margin-bottom: 30px;

  select {
    min-height: 43px;
  }

  textarea {
    resize: vertical;
  }

  .thumbnail {
    margin-bottom: 0 !important;
  }

  /*thumbnail modified*/
  .thumbnail {
    overflow: hidden;
    .bloque {
      max-height: 500px;
      .inicial{
        max-height: 100%;
        height: auto;
        position: relative;
        .imaxeRow {
          max-height: 100%;
          height: auto;
          max-width: 100%;
          width: auto;
          margin-right: 0;
          margin-left: 0;
          img {
            display: block;
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
            margin-right: auto;
            margin-left: auto;
            vertical-align: middle;
          }
        }
      }
      #columnas{
        max-height: 100%;
        height: 500px;
        position: relative;
        .imaxeRow {
          height: 90%;
          margin-right: 0;
          margin-left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            max-height: 500px;
            max-width: 100%;
            height: auto;
            width: auto;
            margin-right: auto;
            margin-left: auto;
            vertical-align: middle;
          }
          .eliminar {
            height: auto;
            position: absolute;
            bottom: 0;
            right: 0;
            font-size: large;
          }
        }
        .botons {
          height: 10%;
          width: 100%;
          .prev,
          .next {
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            position: absolute;
            top: 50%;
            width: auto;
            padding: 16px;
            margin-top: -22px;
            color: #db9600;
            font-weight: bold;
            font-size: xx-large;
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
          }
          .next {
            right: 0;
            border-radius: 3px 0 0 3px;
          }
          .prev:hover,
          .next:hover {
            background-color: rgba(0, 0, 0, 0.8);
          }
          .dots {
            .dot {
              cursor: pointer;
              height: 15px;
              width: 15px;
              margin: 0 2px;
              background-color: #bbb;
              border-radius: 50%;
              display: inline-block;
              transition: background-color 0.6s ease;
            }
            .active, .dot:hover {
              background-color: #717171;
            }
          }
        }
      }
    }
  }
  label.texto-adjuntar {
    width: 100%;
  }

  input {
    border: none;
  }

  //BORDER
  .block-border {
    border: 1px solid $border-color;
  }

  .border-botton {
    border-bottom: 1px solid $border-color;
    padding-bottom: 1px;
  }

  .form-error {
    border: 1px solid $bg-error !important;
    background: $bg-error !important;
  }

  span {
    &.input-group-addon {
      font-size: 30px;
      border: none;
    }
  }

  .input-group {
    width: 100%;
  }

  .input-group .input-group-addon {
    width: 70px;
  }

  .input-image {
    visibility: hidden;
    width: 0;
    position: absolute;
    margin-top: 3px;
  }

  label[for = 'import_file0'],
  label[for = 'import_file1'],
  label[for = 'import_file2'],
  label[for = 'import_file3'],
  label[for = 'import_file4'] {
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  label[for = 'import_file0'].cargado,
  label[for = 'import_file1'].cargado,
  label[for = 'import_file2'].cargado,
  label[for = 'import_file3'].cargado,
  label[for = 'import_file4'].cargado {
    margin-top: -29px;
    position: absolute;
    right: 56px;
    color: $white;
  }
  @media (min-width: 769px) {
    label[for = 'import_file0'].cargado,
    label[for = 'import_file1'].cargado,
    label[for = 'import_file2'].cargado,
    label[for = 'import_file3'].cargado,
    label[for = 'import_file4'].cargado {
      right: 25px;
    }
  }
  @media (min-width: 991px) {
    label[for = 'import_file0'].cargado,
    label[for = 'import_file1'].cargado,
    label[for = 'import_file2'].cargado,
    label[for = 'import_file3'].cargado,
    label[for = 'import_file4'].cargado {
      right: 68px;
    }
  }
  @media (min-width: 1200px) {
    label[for = 'import_file0'].cargado,
    label[for = 'import_file1'].cargado,
    label[for = 'import_file2'].cargado,
    label[for = 'import_file3'].cargado,
    label[for = 'import_file4'].cargado {
      right: 83px;
    }
  }
  @media (max-width: 530px) {
    label[for = 'import_file0'].cargado,
    label[for = 'import_file1'].cargado,
    label[for = 'import_file2'].cargado,
    label[for = 'import_file3'].cargado,
    label[for = 'import_file4'].cargado {
      right: 22px;
    }
  }

  .input-image-load {
    position: absolute;
    right: 136px;
    margin-top: -43px;
  }

  @media (max-width: 1200px) {
    .input-image-load {
      right: 126px;
    }
  }

  @media (max-width: 992px) {
    .input-image-load {
      right: 75px;
    }
  }

  @media (max-width: 768px) {
    .input-image-load {
      right: 107px;
    }
  }

  @media (max-width: 600px) {
    .input-image-load {
      right: 90px;
    }
  }

  .input-group .input-group-addon {
    color: $grey;
  }

  #cambiarDireccion span.btn-change {
    cursor: pointer;
  }

  div#new_direction input#calle,
  div#new_direction input#numero {
    border: solid 1px #d2d6de;
    border-bottom: none !important;
  }
}

//Padding
.p-1 {
  padding: 15px;
}

.ptb-1 {
  padding: 15px 0;
}

.plr-1 {
  padding: 0 15px;
}

//Margin
.mt-1 {
  margin-top: 7px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-3 {
  margin-bottom: 30px;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.swal-overlay--show-modal .swal-modal {
  border-radius: 0 !important;
}

.swal-button {
  background-color: $bg-primary !important;
  border-radius: 0 !important;
}

.swal-title:not(:first-child) {
  padding-bottom: 13px !important;
}

/*

.pdng-form {
  padding: 10px 0px;
}

!* START INPUT FILE *!

.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.custom-file-upload {
  display: block;
  width: auto;
  font-size: 16px;
  margin-top: 30px;
  //border: 1px solid #ccc;
  label {
    display: block;
    margin-bottom: 5px;
  }
}


.file-upload-input {
  width: $file-upload-size;
  color: #4a3a3a;
  font-size: 16px;
  padding: 2px 17px;
  border: none;
  width: 49%;
  background-color: transparent;
  float: left; !* IE 9 Fix *!
  &:hover, &:focus {
    background-color: darken($file-upload-color, 5);
    outline: none;
  }
}

.file-upload-button {
  cursor: pointer;
  display: inline-block;
  color: #4a3a3a;
  font-size: 16px;
  padding: 2px 20px;
  margin-left: -1px;
  width: 49%;
  border-radius: 5px;
  border: 1px solid #4a3a3a;
  background-color: darken($file-upload-color, 10);
  float: left; !* IE 9 Fix *!
  &:hover {
    background-color: darken($file-upload-color, 20);
  }
}

!* FIN INPUT FILE *!

.current-active {
  opacity: 0.7;
}

@media (max-width: 645px) {
  img.ico.logo-public {
    width: 100%;
  }
}

.second-title-pg {
  margin: 0;
  background-color: #f7f7f8;
  padding: 10px;
  margin-bottom: 30px;
  margin: -15px;
  color: #94878a;
}

label.text-label-pg {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .pdg-top {
    margin-top: 44px;
  }
}

.btn-submit {
  padding-bottom: 30px;
}

#bloque-usuario,
#bloque-imaxe {
  margin-top: 20px;
}

.btn-gardar {
  background-color: #1ec498;
}

.login-box, .register-box {
  width: 360px;
  margin: 20% auto;
}

.btn-open-form {
  cursor: pointer;
  display: inline-block;
  color: #4a3a3a;
  font-size: 16px;
  padding: 2px 20px;
  margin-left: -1px;
  width: 49%;
  border-radius: 5px;
  border: 1px solid #4a3a3a;
  background-color: #d1d4d9;
  float: left;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-conf {
  cursor: pointer;
  display: inline-block;
  color: #4a3a3a;
  font-size: 16px;
  padding: 2px 20px;
  margin-left: -1px;
  width: 49%;
  border-radius: 5px;
  border: 1px solid #4a3a3a;
  background-color: #1ec498;
  float: left;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

#mapid {
  height: 300px;
}

#mapid {
  margin-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1055px) {
  .inicio.col-md-2 {
    width: 100% !important;
  }

  .inicio.col-md-10 {
    width: 100% !important;
  }
}
*/

.pagination > .active > a {
  color: #444 !important;
}

.nav-stacked > li.active > a {
  color: #444 !important;
}

.bootstrap-select ul > li.active > a {
  color: #444 !important;
}