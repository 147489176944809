nav {
  &.navbar.mobile {
    margin-bottom: 0 !important;
  }
}

.plr-1 {
  padding-left: 15px;
  padding-right: 15px;
}

.mobile.form-group.select-categoria select {
  padding-left: 0px;
  font-size: 13px;
  font-style: italic;
}


/*Boton + foto*/
.mobile .file-upload-input {
  display: none;
}

.thumbnail.mobile {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}

.thumbnail.mobile img {
  height: 400px;
  width: 100%;
}

/*Titulos*/
.mobile.second-title-pg {
  margin: 0;
  padding: 15px 0;
  background-color: #26508c;
  color: #fff;
  font-size: 20px;
}

.mobile.navbar {
  border: 0;
}

.mobile.form-control {
  border: none;
  box-shadow: none;
  width: 100%;
  //margin-left: 10px;
  margin-right: 5px;
  padding-left: 35px;
  font-size: 11px;
  height: 45px;
}

p.mobile.form-control {
  font-size: 11px;
  height: 23px;
  margin-bottom: 0;
}

.mobile.form-group {
  margin-bottom: 0;
}

.mobile.form-group textarea {
  border: 0;
}

.mobile.icon-form {
  margin-left: 5px;
  position: absolute;
  margin-top: 15px;
  font-size: 16px;
  color: #9a999e;
}

.mobile.row {
  margin-right: 0;
}



.mobile.form-control:not(select) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.mobile.form-control:focus {
  border-color: white;
  box-shadow: none;
}

.mobile.third-title-pg {
  margin: 0;
  padding: 9px 0;
  padding-left: 20px;
  background-color: #8cb7ea;
  color: white;
  font-size: 16px;
}

.mobile.third-title-pg span {
  background-color: #db9600;
  padding: 10px 29px;
  margin-top: -9px;
  color: white;
  height: 100%;
  margin-right: 0;
  cursor: pointer;
  font-size: 88%;
}

.mobile.btn-conf, .mobile.btn-default{
  cursor: pointer;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  padding: 10px 20px;
  margin-left: 0;
  width: 100%;
  border-radius: 0;
  border: 1px solid #3bd1b4;
  background-color: #3bd1b4;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 1px;
}

.mobile.btn-default{
  margin-top: 15px;
  background-color: $border-color;
  border: 1px solid $border-color;
}

footer {
  &.mobile {
    font-size: 10px;
  }
}

.mobile .file-upload-button {
  position: absolute;
  width: auto;
  padding: 8px 18px;
  border: 7px solid #fff;
  border-radius: 200px;
  z-index: 110;
  margin-top: -3em;
  margin-left: 45.5%;
}

.mobile .file-upload-button {
  color: #fff !important;
  background-color: #3b5d94 !important;
}

.tab-content.mobile {
  border-bottom: 5px solid #3b5d94 !important;
}

.mobile.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background-color: #3b5d94 !important;
  color: #fff !important;
  border: none !important;
  //border-top: 1px solid #acacac !important;
  border-radius: 0 !important;
}

.mobile.nav-tabs > li > a {
  color: #3b5d94 !important;
}

/* Multi-language selector */

@media (max-width: 380px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 39.5%;
    width: 0;
  }

  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 1%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 1px 5px;
  }
  .multi-idioma a {
    font-size: 1.32rem;
    color: #fff;
    text-decoration: none !important;
  }

}

@media (min-width: 381px) and(max-width: 480px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 41.5%;
    width: 0;
  }
  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 0.8%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 0px 6px;

  }
  .multi-idioma img {
    width: 14px;
    height: 9px;
  }

}

@media (min-width: 481px) and (max-width: 623px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 42.85%;
    width: 0;
  }
  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 0.8%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 0px 6px;

  }
  .multi-idioma img {
    width: 14px;
    height: 9px;
  }

}

@media (min-width: 624px) and (max-width: 767px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 44.25%;
    width: 0;
  }
  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 0.8%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 0px 6px;

  }
  .multi-idioma img {
    width: 14px;
    height: 9px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 45.5%;
    width: 0;
  }
  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 0.8%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 0px 6px;

  }
  .multi-idioma img {
    width: 14px;
    height: 9px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 46%;
    width: 0;
  }
  /* Multi-langauge selector */
  .multi-idioma {
    z-index: 99;
    position: absolute;
    right: 0.8%;
    top: 0px;
  }
  .multi-idioma li {
    display: inline-block;
    padding: 0px 6px;

  }
  .multi-idioma img {
    width: 14px;
    height: 9px;
  }
}

@media (min-width: 1200px) {
  .mobile .file-upload-wrapper {
    position: absolute;
    z-index: 1;
    left: 47%;
    width: 0;
  }
}

/*thumbnail modified*/
div.thumbnail.mobile {
  height: 400px;
  width: 100%;
  position: relative;
  div.columnas {
    height: 100%;
    .imaxeRow {
      height: 90%;
      margin-right: 0;
      margin-left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        max-height: 400px;
        max-width: 100%;
        height: auto;
        width: auto;
        margin-right: auto;
        margin-left: auto;
        vertical-align: middle;
      }
      .eliminar {
        height: auto;
        position: absolute;
        bottom: 2px;
        right: 2px;
        font-size: large;
      }
    }
    .botons {
      height: 10%;
      width: 100%;
      .prev,
      .next {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -22px;
        color: #db9600;
        font-weight: bold;
        font-size: xx-large;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
      }
      .next {
        right: 0;
        border-radius: 3px 0 0 3px;
      }
      .prev:hover,
      .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
      .dots {
        width: 100%;
        position: absolute;
        bottom: 10%;
        .dot {
          cursor: pointer;
          height: 15px;
          width: 15px;
          margin: 0 2px;
          background-color: #bbb;
          border-radius: 50%;
          display: inline-block;
          transition: background-color 0.6s ease;
        }
        .active, .dot:hover {
          background-color: #717171;
        }
      }
    }
  }
}
